import React from "react";
import style from './authorized_message.module.css';

const AuthorizedMessage = () => {
    return (<>
        <div className={`${style['html']}`}>
            <div className={`${style['body']}`}>
                <div className={`${style['section']}`}>
                    {/* <h1 className={`${style['error']}`}><i class="fa-solid fa-triangle-exclamation"></i></h1> */}
                    <h1 className={`${style['error']}`}><i className="fa-solid fa-money-bill-1"></i></h1>
                    <div className={`${style['page']}`}>
                        Your payment is authorized successfully!
                    </div>
                    {/* <a className={`${style['back-home']}`} href="#!">Back to home</a> */}
                </div>
            </div>
        </div>
    </>);
}

export default AuthorizedMessage;