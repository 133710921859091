import { createSlice } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        value: true,
    },
    reducers: {
        hide: (state) => {
            console.log("hide loader");
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            state.value = false;
        },
        show: (state) => {
            console.log("Show loader");
            state.value = true;
        },
        toggle: (state, action) => {
            console.log("toggle loader");
            state.value = !state.value
        },
    },
})

// Action creators are generated for each case reducer function
export const { show, hide, toggle } = loaderSlice.actions

export default loaderSlice.reducer