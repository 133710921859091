import React from "react";
import loaderCardStyle from './loader.module.css';
import { useSelector } from "react-redux";

const Loader = (props) => {
    const loader = useSelector((state) => state.loader.value);
    return (
        <>
            {
                loader ?
                    <>
                        <div className={`${loaderCardStyle.body}`} >
                            <div className={`${loaderCardStyle.loader}`}>
                                <div className={`${loaderCardStyle.loader__image}`}>
                                    <div className={`${loaderCardStyle.loader__coin}`}>
                                        <img src="/assets/images/coin.png?raw=1" alt="" />
                                    </div>
                                    <div className={`${loaderCardStyle.loader__hand}`}>
                                        <img src="/assets/images/hand.png?raw=1" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null
            }
        </>
    )
}
export default Loader;